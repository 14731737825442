import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import locator from 'utils/locator';

import { sortByOptions } from 'constants/sortByOptions';
import urls from 'constants/urls';
import {
  NLP_CAROUSEL_DESKTOP_TITLE,
  NLP_CAROUSEL_MOBILE_TITLE,
  NLP_CAROUSEL_VIEW_ALL_LINK,
  NLP_CAROUSEL_VIEW_ALL_TITLE,
} from 'constants/nlp-carousel';
import usePrevious from 'hooks/use-previous';

import { GA_GLP_RECOMMENDED_CAROUSEL_TYPE } from 'utils/clickContext/recommendedCarouselContext';
import { isChristmasHubSelector } from 'redux/modules/search-and-browse/selectors/is-christmas-hub';

import BrowseHeader from 'components/BrowseHeader';
import EmptyCategory from 'components/ProductGrid/Empty/Category';
import ContentLocation from 'components/ContentLocation';
import NotFound from 'components/NotFound/NotFound';
import ProductGrid from 'components/ProductGrid';
import ProductsRefinements from 'components/Product/Refinements';
import Skeleton from 'components/Skeleton';
import RecommendedCarousel from 'components/RecommendedCarousel';
import { canShowRecommended } from 'components/RecommendedCarousel/canShowRecommended';
import { getFeatureFlags } from 'utils/feature-flags';
import {
  colourScallopGrey,
  colourCorporateGreen,
} from '@johnlewispartnership/wtr-ingredients/foundations/colours';
import { matchPath } from 'react-router-dom';
import BrowseMetaHeader from './MetaHeader';
import GroceriesLegalNotice from './GroceriesLegalNotice';

import styles from './Browse.scss';

const Browse = ({
  category,
  locationsLoading,
  productsLoading,
  location,
  customerSlotBranchId,
  fetchProducts,
  recommendations,
  hasSEOContent,
  isPageLoading,
  isPendingOrdersLoading,
}) => {
  const branchId = customerSlotBranchId || 'no_branch';
  const previousCustomerSlotBranchId = usePrevious(branchId);
  const isGLP = !!matchPath(locator.pathname, {
    path: '/ecom/shop/browse/groceries',
    exact: true,
    strict: false,
  });

  useEffect(() => {
    if (previousCustomerSlotBranchId && previousCustomerSlotBranchId !== branchId) {
      fetchProducts(category);
    }
  }, [previousCustomerSlotBranchId, branchId, fetchProducts, category]);
  const { pathname = '' } = location;
  const isChristmasHub = useSelector(state => isChristmasHubSelector(state, pathname));
  const categoryNotFound = category === null;

  if (categoryNotFound) {
    return <NotFound />;
  }

  const groceriesHome = pathname.toLowerCase() === urls.groceriesHome;

  const headerLabel = groceriesHome
    ? {
        display: true,
        text: 'Browse by category',
      }
    : null;

  const { glpNLPCarousel, glpWithRecommendations } = getFeatureFlags();

  const showRecommended = glpWithRecommendations && canShowRecommended(recommendations);

  const categoryLoading = category === undefined;

  const pageLoading =
    categoryLoading || locationsLoading || isPageLoading || (isGLP && isPendingOrdersLoading);

  return (
    <Fragment>
      <BrowseMetaHeader location={location} />
      {pageLoading && (
        <Skeleton withSpinner spinnerPosition="top" height="100vh" data-testid="category-loading" />
      )}
      {!pageLoading && (
        <Fragment>
          <div className={styles.browseHeader}>
            <BrowseHeader
              linksLabel={headerLabel}
              location={location}
              isGLP={groceriesHome}
              isChristmasHub={isChristmasHub}
            />
            <ContentLocation name="header" preloadImages />
          </div>
          {showRecommended && (
            <div className={styles.recommendationsWrapper}>
              <div
                className={classNames('container-fluid', styles.removeMarginInMobile)}
                data-testid="rec-prod-carousel"
              >
                <RecommendedCarousel
                  products={recommendations}
                  titleDesktop={glpNLPCarousel ? NLP_CAROUSEL_DESKTOP_TITLE : 'You might like'}
                  titleMobileAndTablet={
                    glpNLPCarousel ? NLP_CAROUSEL_MOBILE_TITLE : 'You might like'
                  }
                  className={styles.chosenForYou}
                  typeForAnalytics={GA_GLP_RECOMMENDED_CAROUSEL_TYPE}
                  linkData={{
                    url: glpNLPCarousel ? NLP_CAROUSEL_VIEW_ALL_LINK : undefined,
                    text: glpNLPCarousel ? NLP_CAROUSEL_VIEW_ALL_TITLE : undefined,
                  }}
                  buttonsTheme={glpNLPCarousel ? 'light' : undefined}
                  backgroundColor={glpNLPCarousel ? colourCorporateGreen : undefined}
                />
              </div>
            </div>
          )}
          <ProductsRefinements hideCategoryFilter sortOptions={sortByOptions.browse} />
          {productsLoading ? (
            <Skeleton
              withSpinner
              spinnerPosition="top"
              height="100vh"
              backgroundColor={colourScallopGrey}
              data-testid="products-loading"
            />
          ) : (
            !isChristmasHub && <ProductGrid Empty={EmptyCategory} />
          )}
          {hasSEOContent && (
            <div data-testid="seoContent" className={styles.seoContent}>
              <ContentLocation name="seoContent" />
            </div>
          )}
          {groceriesHome && <GroceriesLegalNotice />}
        </Fragment>
      )}
    </Fragment>
  );
};

Browse.displayName = 'Browse';

Browse.propTypes = {
  recommendations: PropTypes.arrayOf(PropTypes.shape({})),
  customerSlotBranchId: PropTypes.string,
  fetchProducts: PropTypes.func.isRequired,
  category: PropTypes.shape({
    id: PropTypes.string,
  }),
  locationsLoading: PropTypes.bool.isRequired,
  productsLoading: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.shape({}),
  }),
  hasSEOContent: PropTypes.bool.isRequired,
  isPageLoading: PropTypes.bool.isRequired,
  isPendingOrdersLoading: PropTypes.bool.isRequired,
};

Browse.defaultProps = {
  recommendations: [],
  customerSlotBranchId: undefined,
  category: undefined,
  location: {
    from: null,
    pathname: '',
    state: {
      data: {},
    },
  },
};

export default Browse;
