import { getApiRequestFromSearchQuery } from 'utils/searchQuery';
import setCurrentPageDepthFromPathname from 'redux/modules/page/actions/set-page-depth-using-pathname';
import { searchProductsByCategory } from 'redux/modules/search/actions/search-products-by-category';

export const fetchBrowseProducts = (category, location, isChristmasHub) => dispatch => {
  const { pathname, search: query, state } = location;

  if (!category) {
    return Promise.resolve();
  }

  const params = getApiRequestFromSearchQuery(query);

  dispatch(setCurrentPageDepthFromPathname(pathname));

  return dispatch(
    searchProductsByCategory({
      params: {
        category: category.id,
        filterTags: params && params.filterTags ? params.filterTags : [],
        ...(params && params.sortBy && { sortBy: params.sortBy }),
      },
      refinement: !!state?.refinement,
      isChristmasHub,
    }),
  );
};
