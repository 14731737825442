import { createSelector } from 'reselect';
import { getFeatureFlags } from 'utils/feature-flags';
import isAmendingOrderSelector from 'redux/modules/trolley/selectors/amending-order';
import { isSeasonalSlotDate } from 'utils/checkout/is-seasonal-slot-date';
import { getCustomerSlotDate } from 'redux/modules/customer-slot/selectors';
import { getExperimentValueById } from 'redux/modules/experiments/selectors';
import urls from 'constants/urls';

export const isChristmasHubSelector = createSelector(
  [
    isAmendingOrderSelector,
    getCustomerSlotDate,
    state => getExperimentValueById(state, 'EoS_ChristmasHub'),
    (_state, pathname) => pathname,
  ],
  (amendingOrder = false, slotDate, christmasHubVariant, pathname = '') => {
    const christmasHubFlag = christmasHubVariant === 'showHub';
    const { EoS_ChristmasHub_ForceValidDate: forceValidDate } = getFeatureFlags();
    const isChristmasHubUrl = pathname.toLowerCase() === urls.groceriesChristmas;
    const dateIsValid = isSeasonalSlotDate(
      forceValidDate ? `${new Date().getUTCFullYear()}-12-22` : slotDate,
    );

    return isChristmasHubUrl && christmasHubFlag && amendingOrder && dateIsValid;
  },
);
